.usersList__container {
    flex-grow: 1;
    padding-top: 8px;
}

.usersList__item {
    cursor: pointer;
    padding: 8px;
    width: auto;
    margin-bottom: 12px;
    line-height: 20px;
    border-radius: 20px;
}