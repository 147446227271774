.messages__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid lighten($color: $blue-gray, $amount: 25);

    padding-right: 0px;
    // margin: 16px 0px;

    justify-content: space-between;
    position: relative;

}

@media only screen and (max-width: 768px) {

    .messages__container {
        border-left: none;
    }
  }
