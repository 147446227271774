
html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-family: 'Nunito', sans-serif;

}


body {
    // background-color: lighten($color: $light-blue, $amount: 10);
    background-color: #5BABFC
}

.seciton-title{
    color: $blue-gray;
    font-size: 18px;
    text-align: center;
    font-weight: 600;
}